import Vue from 'vue'
import VueRouter from 'vue-router'
import pcHome from '../views/pcPage/index.vue'
import mobileHome from '@/views/mobilePage/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'pcHome',
    component: pcHome
  },
  {
    path: '/mobileHome',
    name: 'mobileHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:mobileHome
  }
]

const router = new VueRouter({
  routes
})

export default router
