// 用户模块
import request from '@/utils/request'
// **********************************首页接口********************************************8
// 首页推荐三个商品
export function formatParams (obj) {
  const params = {}
  for (const key in obj) {
      if ((obj[key] === 0 || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
          params[key] = obj[key]
      }
  }
  return params
}
export function homeSelectHotSpu (data) {
  return request({
    url: '/api/ma/webstie/selectHotSpu',
    method: 'get',
    data
  })
}
// 首页查询所有地址
export function storesByList (data) {
  return request({
    url: '/api/ma/webstie/storesByList',
    method: 'get',
    data
  })
}
// PC--分页查询品牌咨询 size 容量 current页码
export function newsByPage (data) {
  return request({
    url: '/api/ma/webstie/newsByPage?size='+data.size+'&current='+data.current+'&newsStatus='+data.newsStatus,
    method: 'get',
    data
  })
}
// PC-茶饮系列第二，三个页面--茶饮分类 参数 id 为1为茶饮系列 10为精美茶点
export function selectGoodCateGory (data) {
  return request({
    url: '/api/ma/webstie/selectGoodCateGory',
    method: 'get',
    data
  })
}

// PC-茶饮系列第二，三,四个页面--根据分类商品列表 参数id 为二级分类的pategorySecond 父级分类的categoryFirst 1 为茶饮 10为精美茶点
export function selectByCategorySpu (data) {
  return request({
    url: '/api/ma/webstie/selectByCategorySpu?categorySecond=' + data.categorySecond + '&shelf=1',
    method: 'get',
    data
  })
}
// Pc 根据ID查询新闻详情 newsRich富文本字段
export function webstieNewsById (data) {
  return request({
    url: '/api/ma/webstie/newsById/' + data.id,
    method: 'get',
    data
  })
}
 // Pc 首页查询图片
export function rotateImagesByPages (data) {
  return request({
    url: '/api/ma/webstie/rotateImagesByPages',
    method: 'post',
    data
  })
}
