<template>
  <div class="pcHome">
    <!--分享二维码-->
    <div class="qrcode animate__animated animate__bounceInLeft">
      <el-row>
        <el-col :span="6" style="line-height: 2">
          <div>
            <img src="@/static/home/wx.png" @click="changeshareIndex(1)"
                 :class="shareIndex!==1?'qrcodeIcon1':'qrcodeIcon2'">
          </div>
          <div>
            <img src="@/static/home/dy.png" @click="changeshareIndex(2)"
                 :class="shareIndex!==2?'qrcodeIcon1':'qrcodeIcon2'">
          </div>
          <div>
            <img src="@/static/home/xhs.png" @click="changeshareIndex(3)"
                 :class="shareIndex!==3?'qrcodeIcon1':'qrcodeIcon2'">
          </div>
        </el-col>
        <el-col :span="12">
          <div class=" qrcodeData animate__animated animate__zoomInLeft" v-if="shareIndex !=''">
            <img :src="shareImgUrl" style="width: 100%;border-radius: 10px"/>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="titleMenus animate__animated animate__bounceInDown">
      <div class="titleMenusBox">
        <div class="titleMenusBox1" @click="changeMneus(1)">
          <img src="@/static/home/titleCha.png" class="titleMenuBack" :style="{display:menuIndex==1?'inline-block':''}"/>
          <div style="position: relative;z-index: 1;font-family: titleFont !important;">
            品牌咨询
          </div>
        </div>
        <div class="titleMenusBox1" @click="changeMneus(2)">
          <img src="@/static/home/titleCha.png" class="titleMenuBack" :style="{display:menuIndex==2?'inline-block':''}"/>
          <div style="position: relative;z-index: 1;font-family: titleFont !important;">
            品牌故事
          </div>
        </div>
        <div class="titleMenusBox2" @click="changeMneus(3)">
          <img src="@/static/home/titleMenu1_1.png" style="width: 80px;height: 80px" v-if="menuIndex!==3"/>
          <img src="@/static/home/titleMenu1_2.png" style="width: 232px;height: 91px" v-if="menuIndex==3"/>
        </div>
        <div class="titleMenusBox1" @click="changeMneus(4)">
          <img src="@/static/home/titleCha.png" class="titleMenuBack"  :style="{display:menuIndex==4?'inline-block':''}"/>
          <div style="position: relative;z-index: 1;font-family: titleFont !important;">
            茶饮系列
          </div>
        </div>
        <div class="titleMenusBox1" @click="changeMneus(5)">
          <img src="@/static/home/titleCha.png" class="titleMenuBack" :style="{display:menuIndex==5?'inline-block':''}"/>
          <div style="position: relative;z-index: 1;font-family: titleFont !important;">
            直营门店
          </div>
        </div>
      </div>
    </div>
    <div class="titleImge  animate__animated animate__flipInX" :style="{backgroundImage:'url('+homeImhUrl+')'}" v-loading="loadingData">
    </div>
    <div v-if="menuIndex==3" class="contineData">
      <div class="contineData1">
        <div class="contineData1_1 animate__animated animate__fadeInLeft"></div>
        <div class="contineData1_2 animate__animated animate__fadeInRight" @click="changeMneus(1)" style="cursor: pointer">
          <div class="contineData1_21">
            <div>{{ recordsData.newsTitle?recordsData.newsTitle:"正在查找数据中..." }}</div>
            <div style="margin-top: 16px">陆藜·开了个方子 | {{ recordsData.createTime }}</div>
            <div style="width: 46px;height: 4px;background: #8AAA4B;margin-top: 24px"></div>
            <div style="margin-top: 16px;color:#666666;">{{ recordsData.newsSubtitle }}</div>
          </div>
          <div class="contineData1_22">
            <img :src="recordsData.newsImage" style="width: 100%;height: 100%"/>
          </div>
        </div>
      </div>
      <div class="contineData2">
        <div class="contineData2_1 animate__animated animate__fadeInLeft">
          <div class="contineData2_11"></div>
        </div>
        <div class="contineData2_2 animate__animated animate__fadeInRight" @click="changeMneus(2)" style="cursor: pointer"></div>
      </div>
      <div class="contineData3">
        <div class="contineData3Box animate__animated animate__fadeInLeft"></div>
        <div class="contineData3Box2 animate__animated animate__fadeInRight" v-for="item in selectHotSpuData" @click="changeMneus(4)" style="cursor: pointer">
          <img :src="item.picUrls[0]" alt="" style="width: 100%;height: 100%;"/>
        </div>
      </div>
      <div class="contineData4">
        <div class="contineData4_1 animate__animated animate__fadeInLeft"></div>
        <div class="contineData4Box animate__animated animate__fadeInRight">
          <div class="contineData4Box1" v-for="item in storesByListData" @click="changeMneus(5)" style="cursor: pointer">
            <div style="display: flex;justify-content: flex-start">
              <img src="@/static/home/quan.png" style="width: 23px;height: 24px;padding: 10px">
              <div
                  style="margin-left: 5px;font-size: 30px;font-weight: bold;text-decoration:underline;font-family: titleFont !important;">
                {{ item.storesTitle }}
              </div>
            </div>
            <div
                style="margin-top: 10px;text-align: left;width: 80%;margin-left: 50px;font-size: 16px;position: relative;z-index: 1">
              {{ item.address }}
            </div>
            <img src="@/static/home/address.png"
                 style="width: 104px;height: 50px;position: absolute;bottom: 50px;right: 0">
          </div>
        </div>
      </div>
      <div class="contineData5">
        <div class="contineData5Box">
          <div style="width: 40px;height: 40px;padding-top: 6px">
            <img src="@/static/home/phone.png" style="width: 100%"/>
          </div>
          <div style="margin-left: 10px;padding-top: 9px">
            400-6355-686
          </div>
          <div style="margin-left: 10px;padding-top: 10px">
            陆藜·开了个方子合作资料
          </div>
          <div class="contineData5Boxbotton" @click="changePhone">了解更多</div>
        </div>
      </div>
    </div>
    <div v-if="menuIndex==1" class="ppzxData" v-loading="recordsLoading">
      <div class="contineData1">
        <div class="contineData1_1"></div>
        <div class="contineData1_2"
             style="height: auto;flex-wrap: wrap;align-items: center;background: transparent;margin-top: -30px">
          <div class="contineData1_2" v-for="item in recordsList" style="margin-top: 20px;cursor: pointer"
               @click="changeRecords(item)">
            <div class="contineData1_21">
              <div>{{ item.newsTitle }}</div>
              <div style="margin-top: 16px">陆藜·开了个方子 | {{ item.createTime }}</div>
              <div style="width: 46px;height: 4px;background: #8AAA4B;margin-top: 24px"></div>
              <div style="margin-top: 16px;color:#666666;">{{ item.newsSubtitle }}</div>
            </div>
            <div class="contineData1_22">
              <img :src="item.newsImage" style="width: 100%;height: 100%"/>
            </div>
          </div>
          <div class="contineData1_3" v-if="!isrecordsList" @click="getPPZXData(queryRecords)">加载更多</div>
        </div>
      </div>
    </div>
    <div v-if="menuIndex==2" class="ppgsData">
      <div class="contineData2" style="margin-top: 0">
        <div class="contineData2_1">
          <div class="contineData2_11"></div>
        </div>
        <div class="contineData2_2">

        </div>
      </div>
      <div class="ppgsData1">
        <div class="ppgsData1_1">
        </div>
        <div class="ppgsData1_2">
          <div class="contineData2_21">
            <div class="contineData2_21title1">
              以方入茶 药食同源
            </div>
            <div class="contineData2_21title1" style="margin-top: 10px">
              陆藜·开了个方子
            </div>
            <div style="width: 46px;height: 4px;background: #8AAA4B;margin-top: 10px"></div>
            <div class="contineData2_21title2" style="margin-top: 10px">
              开了个方子以【药食同源】的中式养生理念为灵感，采用【药食同源】食材，与当下年轻人喜爱的茶饮结合，创新产品形式和口味，制作出既好喝又健康。传统和潮流兼具的年轻态国潮养生茶饮。
              <br/>
              <br/>
              <br/>
              所有产品研发依托草本食材及时令果蔬，提升品牌辨识度，植根中式传统食疗理念，产品配方自研、南京中医药大学背书，创始人及研发团队深耕饮品行业多年，具备强势的研发能力，是养生饮品行业先行者。
            </div>
            <div class="contineData2_21box" style="margin-top: 20px">
              <div style="width: 132px;text-align: center">
                <img src="@/static/home/wx1.png" style="width: 100%">
                <div>
                  微信官方账户
                </div>
              </div>
              <div style="width: 132px;text-align: center">
                <img src="@/static/home/dzdp.png" style="width: 100%">
                <div>
                  大众点评平台
                </div>
              </div>
              <div style="width: 132px;text-align: center">
                <img src="@/static/home/xhs1.png" style="width: 100%">
                <div>
                  小红书账户
                </div>
              </div>
            </div>
          </div>
          <div class="contineData2_22">
          </div>
        </div>
      </div>
      <div class="contineData5">
        <div class="contineData5Box">
          <div style="width: 40px;height: 40px;padding-top: 10px">
            <img src="@/static/home/phone.png" style="width: 100%;"/>
          </div>
          <div style="margin-left: 10px;padding-top: 9px">
            400-6355-686
          </div>
          <div style="margin-left: 10px;padding-top: 10px">
            陆藜·开了个方子合作资料
          </div>
          <div class="contineData5Boxbotton" @click="changePhone">了解更多</div>
        </div>
      </div>
    </div>
   <div v-if="menuIndex==4" class="cpxlData" v-loading="cpxlLoading">
      <div class="cpxlDataBox">
        <div class="cpxlDataBox1">
          <div class="cpxlDataBox1data" v-for="(item,index) in selectData" style="margin-top: 20px">
            <div class="cpxlDataBox1data1" @click="changeChildshow(index)">
              <div>{{ item.name }}</div>
            </div>
            <div class="cpxlDataBox1data2" @click="changeChildshow(index)">
              <img :src="item.picUrl" style="width: 100%"></img>
            </div>
            <div v-show="item.childShow" :class="chidrenId!==itme2.id?'cpxlDataBox1data3':'cpxlDataBox1data31'" @click="changeChildid(itme2)" v-for="itme2 in item.children">
              <div class="cpxlDataBox1data3box">
                <div>{{ itme2.name }}</div>
                <img src="@/static/home/lujing.png"  :class="chidrenId==itme2.id?'lujing1':'lujing'">
              </div>
            </div>
          </div>
        </div>
        <div class="cpxlDataBox2">
          <div v-if="categorySpuData.length==0" style="width: 100%;height: 20px;background: #dcdfe6;padding: 10px;margin-top: 20px;">暂无数据</div>

          <div class="cpxlDataBox2back"  v-for="item in categorySpuData">
            <div class="cpxlDataBox2backIM">
              <div>{{item.name}}</div>
              <div style="margin-top: 20px;">
                {{ item.description }}
              </div>
            </div>
            <img :src="item.picUrls[0]" alt="" style="width: 100%;height: 100%;"/>
          </div>
        </div>
      </div>
     <div class="contineData5">
       <div class="contineData5Box">
         <div style="width: 40px;height: 40px;padding-top: 10px">
           <img src="@/static/home/phone.png" style="width: 100%;"/>
         </div>
         <div style="margin-left: 10px;padding-top: 9px">
           400-6355-686
         </div>
         <div style="margin-left: 10px;padding-top: 10px">
           陆藜·开了个方子合作资料
         </div>
         <div class="contineData5Boxbotton" @click="changePhone">了解更多</div>
       </div>
     </div>
   </div>
    <div  v-if="menuIndex==5" class="zymdData">
      <div class="contineData4">
        <div class="contineData4_1"></div>
        <div class="contineData4BoxAll">
          <div class="contineData4Box1All" v-for="item in storesByListAllData">
            <div style="width: 400px;height: 240px">
              <img :src="item.storesImage" style="width: 100%;height: 100%">
            </div>
            <div style="display: flex;justify-content: flex-start;margin-top: 40px">
              <img src="@/static/home/quan.png" style="width: 23px;height: 24px;padding: 10px">
              <div
                  style="margin-left: 5px;font-size: 30px;font-weight: bold;text-decoration:underline;font-family: titleFont !important;">
                {{ item.storesTitle }}
              </div>
            </div>
            <div
                style="margin-top: 10px;text-align: left;width: 80%;margin-left: 50px;font-size: 16px;position: relative;z-index: 1">
              {{ item.address }}
            </div>
            <img src="@/static/home/address.png"
                 style="width: 104px;height: 50px;position: absolute;bottom: 50px;right: 0">
          </div>
        </div>
      </div>
      <div class="contineData5">
        <div class="contineData5Box">
          <div style="width: 40px;height: 40px;padding-top: 10px">
            <img src="@/static/home/phone.png" style="width: 100%;"/>
          </div>
          <div style="margin-left: 10px;padding-top: 9px">
            400-6355-686
          </div>
          <div style="margin-left: 10px;padding-top: 10px">
            陆藜·开了个方子合作资料
          </div>
          <div class="contineData5Boxbotton" @click="changePhone">了解更多</div>
        </div>
      </div>
    </div>
    <div class="fotterData">
      <div class="fotterDataImg">
        <img src="@/static/home/bottom.png" style="width: 100%;height: 100%"/>
      </div>
      <div style="z-index: 1;position: relative">
        <div>
          COPYRIGHT ©2023 江苏百黎堂健康管理有限公司 www.bailitang.com info@bailitang.com 电话：400-6355-686
        </div>
        <div>
          <a href="https://beian.miit.gov.cn/" style="color:black; text-decoration: none;">苏ICP备2020053052号-3</a>
        </div>
        <div>
          联系地址：江苏省南京市建邺区贤坤路78号仁恒置地A座13楼
        </div>
      </div>
    </div>
    <el-dialog
        :title="showRecordsData.newsTitle"
        :visible.sync="dialogVisible"
        width="40%">
      <div class="showHtml">
        <div v-html="showRecordsData.newsRich"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import {rotateImagesByPages,
  newsByPage,
  selectByCategorySpu,
  storesByList, homeSelectHotSpu,selectGoodCateGory} from '@/api'

export default {
  name: 'pcHome',
  data() {
    return {
      dialogVisible: false,
      menuIndex: 3,
      shareIndex: "",
      shareImgUrl: "",
      homeImhUrl: "",
      loadingData: false,
      storesByListData: [],
      storesByListAllData: [],
      selectHotSpuData: [],
      recordsData: {},
      recordsList: [],
      isrecordsList: false,
      recordsLoading: false,
      cpxlLoading: false,
      queryRecords: {},
      showRecordsData: {},
      selectData:[],
      categorySpuData:[],
      chidrenId:""
    }
  },
  // 注册组件
  components: {},
  mounted() {
    window.addEventListener('scroll', this.handleScrolls)
  },
  methods: {
    changePhone(){
      window.location.href = 'tel://' + '4006355686'
    },
    changeChildshow(index){
      this.selectData[index].childShow =  !this.selectData[index].childShow
      if(this.selectData[index].children[0]){
        this.changeChildid(this.selectData[index].children[0])
      }
    },
    changeChildid(itme2){
      this.chidrenId = itme2.id
      this.getSelectByCategorySpu(this.chidrenId,true)
    },
    changeRecords(item) {
      this.showRecordsData = item
      this.dialogVisible = true
    },
    changeMneus(index) {
      this.menuIndex = index
      let name = ['', '新品牌咨询', '新品牌故事', '新首页', '新产品系列', '新直营门店'][index]
      this.getAllCreatdata(name)
      this.$forceUpdate()
    },
    changeshareIndex(index) {
      this.shareIndex = ''
      this.$nextTick(function () {
        this.shareIndex = index
        this.shareImgUrl = require('@/static/home/iconImge' + index + '.jpg')
      });
    },
    getAllCreatdata(name) {
      this.loadingData = true
      rotateImagesByPages({"rotateimageType": name}).then(res => {
        this.loadingData = false
        this.homeImhUrl = res.data.data[0].pcImgUrl
      })
      if (this.menuIndex == 3) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.getHomeData()
      }else if (this.menuIndex == 4) {
        this.getPPCPData()
      }else if (this.menuIndex == 2) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }else if (this.menuIndex == 5) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      } else if (this.menuIndex == 1) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.queryRecords = {
          size: 10,
          current: 1,
          newsStatus: 0
        }
        this.recordsList = []
        this.isrecordsList = false
        this.getPPZXData(this.queryRecords)
      }
    },
    getPPCPData() {
      selectGoodCateGory({}).then(res => {
        this.selectData = res.data.data.map(item =>{
          return {
            ...item,
            childShow:false
          }
        })
        this.chidrenId = this.selectData[0].children[0].id
        this.getSelectByCategorySpu(this.chidrenId)
      })
    },
    getSelectByCategorySpu(chidrenId,isFan){
      let parmes = {
        categorySecond: chidrenId
      }
      this.cpxlLoading = true
      selectByCategorySpu(parmes).then(res => {
        this.cpxlLoading = false
        this.categorySpuData = res.data.data.records
        if(isFan){
          window.scrollTo({
            top: 600,
            behavior: "smooth"
          });
        }
      })
    },
    getPPZXData(parmes) {
      this.recordsLoading = true
      newsByPage(parmes).then(res => {
        console.log(res.data.data)
        this.recordsLoading = false
        if (this.recordsList.length < res.data.data.total) {
          this.recordsList.push(...res.data.data.records)
          this.queryRecords.current++
          if (this.recordsList.length == res.data.data.total) {
            this.isrecordsList = true
          }
        }
      })
    },
    getHomeData() {
      let paginationData = {
        size: 10,
        current: 1,
        newsStatus: 0
      }
      newsByPage(paginationData).then(res => {
        this.recordsData = res.data.data.records[0]
      })
      storesByList().then(res => {
        this.storesByListData = res.data.data.slice(0, 4)
        this.storesByListAllData = res.data.data
      })
      homeSelectHotSpu().then(res => {
        this.selectHotSpuData = res.data.data.slice(0, 3)
      })
    },
    handleScrolls() {
      this.shareIndex = ''
    }
  },
  created() {
    this.getAllCreatdata('新首页')
  }
}
</script>
<style>
.showHtml img{
  width: 100% !important;
}
</style>
<style scoped>

.cpxlDataBoxImg{

}
.contineData5Box {
  display: flex;
  justify-content: end;
  font-size: 24px;
  color: #030000;
  width: 100%;
  margin-right: 48px;
}

.contineData5Boxbotton {
  width: 180px;
  height: 56px;
  background: #8AAA4B;
  color: #FFFFFF;
  border-radius: 28px;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
}

.pcHome {
  background: rgba(244, 244, 244, 1);
}

.contineData4Box {
  width: 895px;
  height: 360px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 59px 48px 59px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.contineData4BoxAll {
  width: 895px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 59px 48px 59px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.contineData4Box1All {
  width: 380px;
  height: 426px;
  position: relative;
}
.contineData4Box1 {
  width: 380px;
  height: 140px;
  position: relative;
}
.contineData4_1 {
  width: 286px;
  height: 360px;
  background: url("@/static/home/contineData5.png") center no-repeat;
  background-size: cover;
}

.contineData4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.contineData3Box {
  width: 286px;
  height: 360px;
  background: url("@/static/home/contineData4.png") center no-repeat;
  background-size: cover;
}

.contineData3Box2 {
  width: 286px;
  height: 360px;
}

.contineData2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ppgsData1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.contineData3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.contineData4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.contineData5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
  height: 160px;
  background: url("@/static/home/contineData6.png") center no-repeat;
  background-size: cover;
}

.contineData2_1 {
  width: 285px;
  height: 600px;
  background: #FFFFFF;
}

.ppgsData1_1 {
  width: 285px;
  height: 488px;
}

.ppgsData1_2 {
  width: 895px;
  height: 488px;
  box-sizing: border-box;
  padding: 38px 45px 38px;
  background: url("@/static/home/ppgsBack.png") center no-repeat;
  background-size: cover;
  display: flex;
  text-align: left;
  justify-content: space-between;
}

.contineData2_11 {
  width: 100%;
  height: 100%;
  background: url("@/static/home/contineData2.png") center no-repeat;
  background-size: cover;
}

.contineData2_21 {
  width: 572px;
}

.contineData2_21title1 {
  font-weight: 400;
  font-size: 16px;
  color: #030000;
}

.contineData2_21title2 {
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}

.contineData2_21box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contineData2_22 {
  width: 236px;
  height: 224px;
  background: url("@/static/home/ppgsback2.png") center no-repeat;
  background-size: cover;
  margin-top: 60px;
}

.contineData2_2 {
  width: 895px;
  height: 600px;
  background: url("@/static/home/contineData3.png") center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
}

.contineData {
  width: 1200px;
  margin: auto;
  position: relative;
  padding: 20px 0 20px;

}

.ppzxData {
  width: 1200px;
  margin: auto;
  position: relative;
  padding: 20px 0 20px;

}
.zymdData{
  width: 1200px;
  margin: auto;
  position: relative;
  padding: 20px 0 20px;
}
.cpxlData {
  width: 1200px;
  margin: auto;
  position: relative;
  padding: 20px 0 20px;

}
.cpxlDataBox{
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.cpxlDataBox1{
  width: 285px;
  margin-top: -20px;
}
.cpxlDataBox1data1{
  width: 100%;
  height: 120px;
  text-align: left;
  position: relative;
  background: url("@/static/home/zixunback1.png") center no-repeat;
  background-size: cover;
  font-family: titleFont !important;
  font-weight: 400;
  font-size: 40px;
  box-sizing: border-box;
  padding-left: 10px;
  color: #030000;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cpxlDataBox1data31{
  width: 100%;
  margin: auto;
  cursor: pointer;
  background: #FFFFFF;
  background: url("@/static/home/zixunback2.png") center no-repeat;
  background-size: cover;
  height: 80px;
}
.cpxlDataBox1data3:hover{
  background: url("@/static/home/zixunback2.png") center no-repeat;
  background-size: cover;
}
.cpxlDataBox1data3:hover .lujing{
  display: inline-block;
}
.lujing{
  width: 24px;
  display: none;
  height: 21px;
}
.lujing1{
  width: 24px;
  display: inline-block;
  height: 21px;
}
.cpxlDataBox1data2{
  width: 100%;
}
.cpxlDataBox1data3{
  width: 100%;
  cursor: pointer;
  margin: auto;
  background: #FFFFFF;
  height: 80px;
}
.cpxlDataBox1data3box{
  width: 90%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
}
.cpxlDataBox2{
  width: 895px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -20px;
  height: 360px;
  position: relative;
}
.cpxlDataBox2:hover .cpxlDataBox2backIM{
  display: inline-block;
}
.cpxlDataBox2back{
  width: 285px;
  height: 285px;
  margin-top: 20px;
  position:relative;
  cursor: pointer;
}
.cpxlDataBox2backIM{
  cursor: pointer;
  z-index: 1;
  display: none;
  position: absolute;
  color: #535D43;
  background: #FFFFFF;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  padding: 26px 26px 26px;
  box-sizing: border-box;
  text-align: left;
}
.ppgsData {
  width: 1200px;
  margin: auto;
  position: relative;
  padding: 20px 0 20px;

}

.contineData1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contineData1_1 {
  width: 285px;
  height: 160px;
  background: url("@/static/home/xunxi.png") center no-repeat;
  background-size: cover;
}

.contineData1_3 {
  width: 895px;
  height: 36px;
  margin-top: 20px;
  background: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  color: #8AAA4B;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
}

.contineData1_2 {
  width: 895px;
  height: 160px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 12px 30px 12px;
  display: flex;
  justify-content: space-between;
  //border-bottom: 4px;
  //border-color: #8AAA4B;

}

.contineData1_21 {
  width: 352px;
  height: 16px;
  font-weight: 400;
  font-size: 16px;
  color: #030000;
  text-align: left;
  font-style: normal;
}

.contineData1_22 {
  width: 241px;
  height: 136px;
}

.qrcodeData {
  width: 146px;
  height: 146px;
  background: #FFFFFF;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 3px;
}

.titleMenuBack {
  width: 95px;
  height: 89px;
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px
}

.fotterData {
  position: relative;
  width: 100%;
  height: 22vh;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: #030000;
  line-height: 1.5;
  box-sizing: border-box;
  padding-top: 43px;
  background: #FFFFFF;
}

.fotterDataImg {
  position: absolute;
  width: 8vw;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
}

.titleMenus {
  background: url("@/static/home/titleBack.png") center no-repeat;
  background-size: cover;
  width: 100%;
  height: 140px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  z-index: 3;
}

.titleMenusBox {
  width: 80%;
  font-family: titleFont !important;
  margin: auto;
  display: flex;
  font-weight: 400;
  font-size: 32px;
  color: #030000;
  height: 100%;
  justify-content: space-between;
}

.titleMenusBox1 {
  width: 228px;
  text-align: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.titleMenusBox1:hover img{
  display: inline-block;
}

.titleMenusBox2 {
  width: 400px;
  text-align: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.titleImge {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 66.7vh;
  margin-top: 140px;
}

.qrcode {
  position: fixed;
  left: 4vw;
  width: 200px;
  height: 200px;
  bottom: 26vh;
}

.qrcodeIcon1 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.qrcodeIcon2 {
  width: 40px;
  cursor: pointer;
  height: 40px;
  filter: drop-shadow(2px 4px 6px #8aaa4b);
}
</style>
