<template>
  <div id="app">
    <router-view/>
    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        :close-on-click-modal="isShow"
        :close-on-press-escape ="isShow"
        :show-close = "isShow"
        :width="modelData == 'pc'?'40%':'95%'">
      <div style="position: absolute;left: 2%;top: 3%;width: 100px;">
        <img src="@/static/home/titleMenu1_2.png" alt="" style="width: 100%;height: 100%;" />
      </div>
      <div :style="{'line-height':modelData == 'pc'?'2':'1.3'}" style="width: 100%;font-size: 16px;color: #535d43;position: relative;">
        <div style="text-align: center;width: 100%;font-size: 26px;">
          <strong>郑重声明</strong>
        </div>
        <div style="margin-top: 10px;">
          近日，我司发现有不法分子冒充“陆藜开了个方子”员工进行不法招商，对公司内部情况以及投资模型进行滋扰混淆，已经对我司造成了不好影响，严重影响品牌形象！对此，我司高度重视，为保障社会公众合法权益，维护本公司声誉，特此郑重声明：
        </div>
        <div style="margin-top: 10px;">
          一、咨询的客户在与陆藜开了方子市场老师咨询项目时可直接开视频验证公司员工真实性，以免上当受骗，如因联系冒用公司而引起的有关经济、法律纠纷，一概与我司无关！
        </div>
        <div style="margin-top: 10px;">
          二、请认准我司官方渠道：
          <br/>
          官方电话：400-635-5686
          <br/>
          官方微信：JS-bailitang
          <br/>
          官方小红书：6457447481/943313490
          <br/>
          官方抖音：59538187642
          <br/>
          官方快手：SCM17625281586
          <br/>
        </div>
        <div style="margin-top: 10px;">
          三、对任何侵犯我司正当权益的行为，我司将保留追究相关人员法律责任的权力。
        </div>
        <div style="margin-top: 10px;">
          四、恳请广大公众提高警惕、谨防受骗。
        </div>
        <div style="margin-top: 10px;width: 100%;text-align: right;">
          南京朵朵品牌管理有限公司
        </div>
        <div style="margin-top: 10px;width: 100%;text-align: right;">
          日期：2024.1.1
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="width: 100%;text-align: center;">
        <el-button type="primary" @click="clickKnow" style="width: 200px;background: #535d43;border-color: #e9d1ab;color: #e9d1ab;">我已知晓</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'app',
  data() {
    return {
      isShow:false,
      dialogVisible:false,
      modelData: '',
      isShowPhone: false,
      isShowqrcode: 0,
      isShowQr: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrolls)
  },
  methods: {
    clickKnow(){
      window.localStorage.setItem('iKnow',"0");
      this.dialogVisible = false
    },
    handleScrolls() {
      this.isShowQr = false
      this.isShowqrcode = 0
    },
    browserRedirect() {
      document.onselectstart = new Function("return false");
      const pathUrl = this.$route.path
      const sUserAgent = navigator.userAgent.toLowerCase()
      const bIsIpad = sUserAgent.match(/ipad/i) == 'ipad'
      const bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
      const bIsMidp = sUserAgent.match(/midp/i) == 'midp'
      const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
      const bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
      const bIsAndroid = sUserAgent.match(/android/i) == 'android'
      const bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
      const bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'
      if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        // 移动端页面
        this.modelData = 'phone'
        this.$router.push('/mobileHome').catch(() => { })
      } else {
        // pc端页面
        this.modelData = 'pc'
        this.$router.push('/').catch(() => { })
      }
    }
  },
  created() {
    this.browserRedirect()
    this.$forceUpdate()
    if(window.localStorage.getItem('iKnow')){
      this.dialogVisible = false
    }else{
      this.dialogVisible = true
    }
    this.$nextTick(() => {
    // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
    // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
    //禁止f12
      document.οnkeydοwn = new Function('event.returnValue=false')
    })
    // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
    document.onkeydown = function (e) {
      if (e && e.keyCode === 123) {
        e.returnValue = false
    // e.keyCode = 0 //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
        return false
      }
    }
  },
  beforeUpdate() { },
  beforeMount() {

  }
}
</script>
<style>
body{
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*{
  font-family: contentFont;
}
@font-face {
  font-family: "titleFont";
  src: url('@/static/fontFamily/titleFont.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "contentFont";
  src: url('@/static/fontFamily/contentFont.ttf');
  font-weight: normal;
  font-style: normal;
}
</style>
<script setup>
</script>
