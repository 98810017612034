<template>
  <div class="mobilePage">
    <div class="mobilePageHome" v-if="pageIndex==0">
      <div class="mobilePagetitlte animate__animated animate__zoomIn" :style="{backgroundImage:'url('+homeImhUrl+')'}"></div>
      <div class="mobilePageHome1"  @click="changePhone">
        <div class="flebu">
          <img src="@/static/phone/phone.png" style="width: 24px;height: 24px"/>
          <div style="margin-left: 12px" >陆藜·开了个方子合作</div>
        </div>
        <div style="width: 24px;height: 24px">
          <img src="@/static/phone/gengduo.png" style="width: 100%;"/>
        </div>
      </div>
      <div class="flebu" style="margin-top: 12px">
        <div class="mobilePageHome2_1 animate__animated animate__fadeInLeft" @click="changePageIndex(1)"></div>
        <div class="mobilePageHome2_2 animate__animated animate__fadeInRight" @click="changePageIndex(2)"></div>
      </div>
      <div v-if="recordsData.newsTitle" class="mobilePageHome3 flebu animate__animated animate__flipInX" @click="changePageIndex(3)">
        <div style="width: 43vw">
          <div>{{ recordsData.newsTitle }}</div>
          <div style="width: 27px;height: 2px;background: #8AAA4B;margin-top: 10px"></div>
        </div>
        <div style="width: 141px;height: 80px;">
          <img :src="recordsData.newsImage" style="width: 100%"/>
        </div>
      </div>
      <div class="mobilePageHome4 animate__animated animate__fadeInUpBig" @click="changePageIndex(4)"> </div>
    </div>
    <div v-if="pageIndex!==0" class=" mobilePageBack animate__animated animate__backInDown">
      <div @click="changePageIndex(0)" style="position: absolute;left: 10px;top: 1vw;">
        <i class="el-icon-arrow-left" style="font-size: 16px" ></i>
      </div>
      <div>
        {{['','品牌故事','直营门店','品牌咨询','产品系列'][pageIndex]}}
      </div>
    </div>
    <div class="ppgsData" v-if="pageIndex==1">
      <div class="ppgsData1"></div>
      <div class="ppgsData2"></div>
    </div>
    <div v-if="pageIndex==2" class="zymdData">
      <div class="zymdData1" v-if="storesByListAllData.length>0">
        <div class="zymdData1Box animate__animated animate__bounceInUp" v-for="item in storesByListAllData">
          <div class="zymdData1BoxTitle" >
            <img :src="item.storesImage" style="width: 100%">
          </div>
          <div  class="flebu" style="margin-top: 15px;position: relative;z-index: 1">
            <div style="width: 14px;height: 14px;padding-top: 5px">
              <img src="@/static/home/quan.png" style="width: 100%">
            </div>
            <div style="width: 135px;text-align: left">
              <div style="font-family: titleFont !important;font-size: 16px;font-weight: 500;text-decoration:underline;">{{ item.storesTitle }}</div>
              <div style="font-size: 12px"> {{ item.address }}</div>
            </div>
          </div>
          <img src="@/static/home/address.png" style="width: 50px;height: 25px;position: absolute;bottom: 10px;right: 0;z-index: 0">

        </div>
      </div>
      <div v-if="storesByListAllData.length>0" class="mobilePageHome1 animate__animated animate__bounceInUp" style="margin-top: 10px"  @click="changePhone">
        <div class="flebu" >
          <img src="@/static/phone/phone.png" style="width: 24px;height: 24px"/>
          <div style="margin-left: 12px">陆藜·开了个方子合作</div>
        </div>
        <div style="width: 24px;height: 24px">
          <img src="@/static/phone/gengduo.png" style="width: 100%;"/>
        </div>
      </div>
    </div>
    <div class="ppzxData" v-if="pageIndex==3" v-loading="recordsLoading">
      <div v-if="recordsLoading" style="height: 100px">
      </div>
      <div class="mobilePageHome3 flebu" v-for="item in recordsList" @click="changeShowItme(item)">
        <div style="width: 43vw">
          <div>{{ item.newsTitle }}</div>
          <div style="width: 27px;height: 2px;background: #8AAA4B;margin-top: 10px"></div>
        </div>
        <div style="width: 141px;height: 80px;">
          <div class="ppzxDataImg" :style="{backgroundImage:'url('+item.newsImage+')'}"></div>
        </div>
      </div>
      <div class="contineData1_3" v-if="!isrecordsList" @click="getnewsByPage(queryRecords)">加载更多</div>
    </div>
    <div class="cpxlData" v-if="pageIndex==4">
      <div class="cpxlDataMenu" @click="changedrawer">
        <i class="el-icon-s-unfold"  style="color: #FFFFFF;font-size: 30px;margin-left: 10px"></i>
      </div>
      <div class="zanwushuju" v-if="categorySpuData.length==0">
        <div>
          暂无数据
          <br/>
          <span style="color: #9E9E9E">请点下右下角菜单重新选择系列 !</span>
        </div>
      </div>
      <div class="cpxlDataBox" v-loading="cpxlLoading">
        <div class="cpxlDataBoxData animate__animated animate__pulse" v-for="(item,index) in categorySpuData" @click="clickIsshow(index)">
          <div class="cpxlDataBox2backIM animate__animated animate__flipInY" v-if="item.isclick">
            <div>{{item.name}}</div>
            <div style="margin-top: 20px;">
              {{ item.description }}
            </div>
          </div>
          <img class="animate__animated animate__flipInY" :src="item.picUrls[0]" alt="" style="width: 100%;height: 100%;" v-if="!item.isclick"/>
        </div>
      </div>
    </div>
    <div class="fotterData animate__animated animate__fadeInUpBig">
      <div class="fotterDataImg">
        <img src="@/static/home/bottom.png" style="width: 100%;height: 100%"/>
      </div>
      <div style="z-index: 1;position: relative;">
        <div>
          COPYRIGHT ©2023 江苏百黎堂健康管理有限公司<br/>
          www.bailitang.com   info@bailitang.com<br/>
          电话：400-6355-686<br/>
          <a href="https://beian.miit.gov.cn/"  style="color:black; text-decoration: none;">苏ICP备2020053052号-3</a>
        </div>
        <div>
          联系地址：江苏省南京市建邺区贤坤路78号仁恒置地A座13楼
        </div>
      </div>
    </div>
    <el-drawer
        :title="showRecordsData.newsTitle"
        :visible.sync="dialogVisible"
        direction="ttb">
      <div class="showHtml">
        <div v-html="showRecordsData.newsRich"></div>
      </div>
    </el-drawer>

    <el-drawer
        title="产品系列"
        :visible.sync="drawer"
        direction="rtl">
      <div style="background: rgba(244, 244, 244, 1);padding-top: 1px">
        <div class="cpxlDataBox1">
          <div class="cpxlDataBox1data" v-for="(item,index) in selectData" :style="{'margin-top': index==0?'1px':'10px'}">
            <div class="cpxlDataBox1data1" @click="changeChildshow(index)">
              <div>{{ item.name }}</div>
            </div>
            <div class="cpxlDataBox1data2"  @click="changeChildshow(index)">
              <img :src="item.picUrl" style="width: 100%"></img>
            </div>
            <div v-show="item.childShow"  :class="chidrenId!==itme2.id?'cpxlDataBox1data3':'cpxlDataBox1data31'" @click="changeChildid(itme2)" v-for="itme2 in item.children">
              <div class="cpxlDataBox1data3box">
                <div>{{ itme2.name }}</div>
                <img src="@/static/home/lujing.png"  :class="chidrenId==itme2.id?'lujing1':'lujing'">
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api'

export default {
  name: 'mobilePage',
  data () {
    return {
      drawer:false,
      showRecordsData:{},
      dialogVisible:false,
      shareIndex:"",
      shareImgUrl:"",
      homeImhUrl:"",
      recordsData:{},
      queryRecords:{},
      pageIndex:0,
      storesByListAllData:1,
      recordsLoading:false,
      recordsList:[],
      selectData:[],
      chidrenId:"",
      categorySpuData:[],
      cpxlLoading:false,
    }
  },
  // 注册组件
  components: {
  },
  mounted() {
    window.onpopstate = () => {
      if (!this.allowBack) {    //    这个allowBack 是存在vuex里面的变量
        this.changePageIndex(0)
        history.go(1)
      }
    }
  },
  methods: {
    clickIsshow(index){
      this.categorySpuData[index].isclick = !this.categorySpuData[index].isclick
    },
    changeChildshow(index){
      this.selectData[index].childShow =  !this.selectData[index].childShow
    },
    changePhone(){
      window.location.href = 'tel:4006355686'
    },
    changeChildid(itme2){
      this.chidrenId = itme2.id
      this.drawer = false
      this.getSelectByCategorySpu(this.chidrenId)
    },
    changedrawer(){
      this.drawer = true
    },
    changeShowItme(item){
      this.dialogVisible = true
      this.showRecordsData = item
    },
    changePageIndex(index){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.pageIndex = index
      this.getAllCreatdata()
    },
    getstoresByList(){
      storesByList().then(res => {
        this.storesByListAllData = res.data.data
      })
    },
    getAllCreatdata(){
      rotateImagesByPages({"rotateimageType": "新首页"}).then(res => {
        this.homeImhUrl = res.data.data[0].phoneImgurl
      })
      if(this.pageIndex == 0){
        this.getHomeData()
      }
      if(this.pageIndex == 2){
        this.getstoresByList()
      }
      if(this.pageIndex == 3){
        this.queryRecords = {
          size: 10,
          current: 1,
          newsStatus: 0
        }
        this.recordsList = []
        this.getnewsByPage(this.queryRecords)
      }
      if(this.pageIndex == 4){
        this.getPPCPData()
      }
    },
    getnewsByPage(parmes){
      this.recordsLoading = true
      newsByPage(parmes).then(res => {
        console.log(res.data.data)
        this.recordsLoading = false
        if (this.recordsList.length < res.data.data.total) {
          this.recordsList.push(...res.data.data.records)
          this.queryRecords.current++
          if (this.recordsList.length == res.data.data.total) {
            this.isrecordsList = true
          }
        }
      })
    },
    getPPCPData() {
      selectGoodCateGory({}).then(res => {
        this.selectData = res.data.data.map(item =>{
          return {
            ...item,
            childShow:false
          }
        })
        this.chidrenId = this.selectData[0].children[0].id
        this.getSelectByCategorySpu(this.chidrenId)
      })
    },
    getSelectByCategorySpu(chidrenId){
      let parmes = {
        categorySecond: chidrenId
      }
      this.cpxlLoading = true
      this.categorySpuData= []
      selectByCategorySpu(parmes).then(res => {
        this.cpxlLoading = false
        this.categorySpuData = res.data.data.records.map(item=>{
          return{
            ...item,
            isclick:false
          }
        })
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      })
    },
    getHomeData() {
      let paginationData = {
        size: 10,
        current: 1,
        newsStatus: 0
      }
      newsByPage(paginationData).then(res => {
        this.recordsData = res.data.data.records[0]
      })
    }
  },
  created () {
    this.getAllCreatdata()
  }
}
</script>
<style>
.el-drawer__header{
  margin-bottom: 10px !important;
}

.showHtml img{
  width: 100% !important;
}
.el-drawer__open .el-drawer.rtl{
  width: 80% !important;
}
.el-drawer__open .el-drawer.ttb{
  height: 100% !important;
}
</style>
<style scoped>
.contineData1_3 {
  width: 100%;
  height: 36px;
  margin-top: 20px;
  background: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  color: #8AAA4B;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
}

.cpxlDataMenu{
  position: fixed;
  bottom: 20vh;
  right: 0;
  width: 60px;
  background: #8AAA4B;
  height: 40px;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 5;
}
.zanwushuju{
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}
.cpxlDataBox{
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cpxlDataBoxData{
  width: 48vw;
  height: 48vw;
  position: relative;
  margin-top: 4vw;
}
.cpxlDataBox2backIM{
  cursor: pointer;
  z-index: 1;
  position: absolute;
  color: #535D43;
  background: #FFFFFF;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  padding: 26px 26px 26px;
  box-sizing: border-box;
  text-align: left;
  overflow: auto;
}
.cpxlDataBox1{
  padding-bottom: 50px;
}
.cpxlDataBox1data1{
  width: 100%;
  height: 120px;
  text-align: left;
  position: relative;
  background: url("@/static/home/zixunback1.png") center no-repeat;
  background-size: cover;
  font-family: titleFont !important;;
  font-weight: 400;
  font-size: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  color: #030000;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cpxlDataBox1data3{
  width: 100%;
  cursor: pointer;
  margin: auto;
  background: #FFFFFF;
  height: 80px;
}
.cpxlDataBox1data3box{
  width: 90%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
}
.cpxlDataBox1data31{
  width: 100%;
  margin: auto;
  cursor: pointer;
  background: #FFFFFF;
  background: url("@/static/home/zixunback2.png") center no-repeat;
  background-size: cover;
  height: 80px;
}

.cpxlData{
  width: 100%;
  min-height: calc(100vh - 250px);
}
.ppzxDataImg{
  width: 100%;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
}
.flebu{
  display: flex;
  justify-content: space-between;
}
.zymdData1{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.zymdData1Box{
  width: 46vw;
  height: 46vw;
  padding: 9px;
  background: #FFFFFF;
  box-sizing: border-box;
  margin-top: 10px;
  position: relative;
}
.zymdData1BoxTitle{
  width: 100%;
}
.ppzxData{
  width: 100%;
  min-height: calc(100vh - 250px);
}
.ppgsData{
  width: 100%;
  min-height: calc(100vh - 250px);
}
.zymdData{
  width: 100%;
  min-height: calc(100vh - 250px);
}
.ppgsData2{
  width: 100vw;
  height: 120vw;
  background: url("@/static/phone/ppgs1.png") center no-repeat;
  background-size: cover;
}
.ppgsData1{
  width: 100vw;
  height: 67vw;
  background: url("@/static/home/contineData3.png") center no-repeat;
  background-size: cover;
}
.mobilePageHome2_1{
  width: 47vw;
  height: 47vw;
  background: url("@/static/phone/menu1.png") center no-repeat;
  background-size: cover;
}
.mobilePageHome2_2{
  width: 47vw;
  height: 47vw;
  background: url("@/static/phone/menu2.png") center no-repeat;
  background-size: cover;
}
.mobilePageHome3{
  margin-top: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: left;
  background: #FFFFFF;
}
.mobilePageHome4{
  width:100%;
  height: 47vw;
  background: url("@/static/phone/menu3.png") center no-repeat;
  background-size: cover;
  margin-top: 10px;
}
.mobilePageHome1{
  width:100%;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 26px 21px 21px;
  display: flex;
  justify-content: space-between;
}
.mobilePagetitlte{
  width: 100%;
  height: 503px;
  background-size: cover;
  background-repeat: no-repeat;
}
.mobilePageHome{
  width: 100%;
}
.mobilePageBack{
  width: 100%;
  background: #FFFFFF;
  height: 30px;
  text-align: center;
  position: relative;
}

.mobilePage{
  background: rgba(244, 244, 244, 1);
}
.fotterData{
  font-weight: 400;
  font-size: 14px;
  color: #030000;
  height: 246px;
  padding: 39px 46px 39px;
  box-sizing: border-box;
  position: relative;
}
.fotterDataImg {
  position: absolute;
  width: 90px;
  top: 60%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
}
</style>
